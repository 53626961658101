@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url("/static/Roboto-Light.ttf") format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url("/static/OpenSans-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'Sulphur Point';
    font-style: normal;
    font-weight: 400;
    src: url("/static/SulphurPoint-Regular.ttf") format('truetype');
}

* {
    margin: 0;
    padding: 0;
}

html,
body {
    overflow: hidden;
    /* background: radial-gradient(circle, rgba(48, 46, 46, 1) 0%, rgba(26, 22, 22, 1) 87%); */
    background: rgb(36, 32, 32);
    color: #d3d3d3;
    font-family: 'Open Sans', sans-serif;
}

.intro-container {
    position: absolute;
    top: 50%;
    left: 50%;
}

.intro {
    width: 161px;
    position: relative;
    left: -80px;
    top: -70px;
}

.webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    opacity: 0;
}

.header {
    position: absolute;
    top: 25px;
    left: 20px;
    opacity: 0;
    width: 111px;
}

.welcome-container {
    height: 100vh;
    text-align: center;
    /* outline: 1px solid green;
    outline-offset: -1px; */
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    /* max-width: 700px; */
    min-width: 700px;
}

.welcome-container h1 {
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 50px;
    color: #ffffff;
    margin-bottom: 8px;
    opacity: 0;
}

.welcome-container h2 {
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 50px;
    color: #ffffff;
    margin-bottom: 8px;
    opacity: 0;
}

.welcome-container p {
    font-family: 'Open Sans';
    font-weight: 300;
    font-size: 14px;
    color: #dcdcdc;
    line-height: 20px;
    margin-bottom: 24px;
    opacity: 0;
}

.bolder {
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    /* text-decoration: underline; */
}

.contact {
    position: relative;
    left: 50%;
    width: 190px;
}

.contact a {
    /*  fix strange blue glitch on a links in footer icons */
    color: rgba(255, 255, 255, 0);
    text-decoration: none;
}

.welcomesmallcenterer {
    /* 460 */
    max-width: 510px;
    /* outline: 1px solid red;
    outline-offset: 1px; */
    margin-left: auto;
    margin-right: auto;
}

.contact_centrer {
    position: relative;
    left: -90px;
    /* outline: 1px solid red;
    outline-offset: 1px; */
}

.button_mail_style {
    width: 30px;
    opacity: 0;
    margin-right: 0.5em;
    visibility: hidden;
}

.button_tel_style {
    width: 40px;
    height: 27px;
    opacity: 0;
    margin-right: 0.5em;
    visibility: hidden;
}

.button_cv_style {
    width: 40px;
    height: 27px;
    opacity: 0;
    margin-right: 0.5em;
    visibility: hidden;
}

.button_li_style {
    width: 40px;
    height: 26px;
    opacity: 0;
    position: relative;
    top: -3px;
    left: -18px;
    visibility: hidden;
}

.ontop {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    background: radial-gradient(circle, rgba(48, 46, 46, 0) 0%, rgba(26, 22, 22, 0.7) 87%);
}


/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
    .vertical-center {
        max-width: 550px;
    }
    .welcome-container h1 {
        font-size: 26px;
    }
    .welcome-container h2 {
        font-size: 26px;
    }
    .welcome-container p {
        font-size: 11px;
    }
    .welcomesmallcenterer {
        max-width: 300px;
        /* outline: 1px solid red;
        outline-offset: 1px; */
    }
    .bolder {
        font-size: 12px;
    }
}


/* Small evices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) {}


/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) {}


/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) {}


/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {}